@use "../foundation/import" as *

/* -------------------------------
 *  config
 * ---------------------------- */
$container-gutter: var(--space-XS) !default

/* -------------------------------
 *  container wrapper & inner
 * ---------------------------- */
.l-wrapper
  width: 100%
.l-inner
  padding: 0 $container-gutter
  &.l-inner-nospace
    padding: 0

/* -------------------------------
 *  gird system (container)
 * ---------------------------- */
.l-container
  // width: 100%
  width: 80%
  max-width: calc(var(--screen-xl) * 1px)
  margin: 0 auto
.l-container-full
  width: 100%
  max-width: 100%
  margin: 0
+media-query(xxl)
  .l-container
    max-width: calc(var(--screen-xl) * 1px)
+media-query(xl)
  .l-container
    max-width: calc(var(--screen-lg) * 1px)
+media-query(lg)
  .l-container
    max-width: calc(var(--screen-md) * 1px)
+media-query(md)
  .l-container
    max-width: 100%
+media-query(sm)
  .l-container
    max-width: 100%
    width: 85%
+media-query(xs)
  .l-container
    max-width: 100%
    width: 96%
+media-query(xxs)
  .l-container
    max-width: 100%
    width: 96%

/* -------------------------------
 *  gird system (row & column)
 * ---------------------------- */
.l-row 
  width: 100%
  &.l-row--flex
    display: flex
  &.l-row--grid
    display: grid

.l-column
  &.l-column--1
    width: 8.3333%
  &.l-column--2
    width: 16.6666%
  &.l-column--3
    width: 25%
  &.l-column--4
    width: 33.3333%
  &.l-column--5
    width: 41.6666%
  &.l-column--6
    width: 50%
  &.l-column--7
    width: 58.3333%
  &.l-column--8
    width: 66.6666%
  &.l-column--9
    width: 75%
  &.l-column--10
    width: 83.3333%
  &.l-column--11
    width: 91.6666%
  &.l-column--12
    width: 100%