@use "../../foundation/import" as *

/* -------------------------------
 *  navigation elements
 * ---------------------------- */
.c-navi-footer-sitelink
  .__list 
    display: flex
    align-items: baseline
    .__item
      line-height: 1
      &:not(:first-child)
        margin: 0 0 0 30px
      .__link
        text-decoration: none
        font-size: 1.4rem
        letter-spacing: 0.1rem