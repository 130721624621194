@use "../../foundation/import" as *

/* -------------------------------
 *  list components
 * ---------------------------- */
.u-list-ordered
  & > li
    counter-increment: number
    padding: 0 0 0 1.25em
    position: relative
    &::before
      content: counter(number)"."  
      position: absolute
      left: 0
      top: 0
    &:not(:first-child)
      margin: 0.25em 0 0 0
