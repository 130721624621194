@use "variable" as *
@use "color" as *
@use "animation" as *

/* -------------------------------
 *  css custom properties
 * ---------------------------- */
:root
  // default style
  --default-color: #{$default-color}
  --default-font-size: #{$default-font-size}
  --default-font-family: #{$default-font-family}
  --default-line-height: #{$default-line-height}
  --default-letter-spacing: #{$default-letter-spacing}
  --default-border-radius: #{$default-border-radius}
  --default-transition: #{$default-transition}
  --default-text-shadow: #{$default-text-shadow}
  --default-box-shadow: #{$default-box-shadow}
  --default-box-shadow-inner: #{$default-box-shadow-inner}
  // hover style
  --hover-color: #{$hover-color}
  --hover-box-shadow: #{$hover-box-shadow}
  // screen size & breakpoint
  --screen-xxl: #{$screen-xxl}
  --screen-xl: #{$screen-xl}
  --screen-lg: #{$screen-lg}
  --screen-md: #{$screen-md}
  --screen-sm: #{$screen-sm}
  --screen-xs: #{$screen-xs}
  --screen-xxs: #{$screen-xxs}
  // font family
  --font-sans-serif: #{$font-sans-serif}
  --font-serif: #{$font-serif}
  --font-monospace: #{$font-serif}
  // space size
  --space-XXXXL: #{$space-XXXXL}
  --space-XXXL: #{$space-XXXL}
  --space-XXL: #{$space-XXL}
  --space-XL: #{$space-XL}
  --space-L: #{$space-L}
  --space-M: #{$space-M}
  --space-R: #{$space-R}
  --space-S: #{$space-S}
  --space-XS: #{$space-XS}
  --space-XXS: #{$space-XXS}
  --space-XXXS: #{$space-XXXS}
  // font size
  --font-XXXXL: #{$font-XXXXL}
  --font-XXXL: #{$font-XXXL}
  --font-XXL: #{$font-XXL}
  --font-XL: #{$font-XL}
  --font-L: #{$font-L}
  --font-M: #{$font-M}
  --font-R: #{$font-R}
  --font-S: #{$font-S}
  --font-XS: #{$font-XS}
  --font-XXS: #{$font-XXS}
  --font-XXXS: #{$font-XXXS}
  // line spacing
  --line-height-caption: #{$line-height-caption}
  --line-height-bodycopy: #{$line-height-bodycopy}
  --line-height-leadcopy: #{$line-height-leadcopy}
  --line-height-headline: #{$line-height-headline}
  --line-height-subcatch: #{$line-height-subcatch}
  --line-height-catchcopy: #{$line-height-catchcopy}
  --line-height-title: #{$line-height-title}
  // kerning
  --letter-spacing-caption: #{$letter-spacing-caption}
  --letter-spacing-bodycopy: #{$letter-spacing-bodycopy}
  --letter-spacing-leadcopy: #{$letter-spacing-leadcopy}
  --letter-spacing-headline: #{$letter-spacing-headline}
  --letter-spacing-subcatch: #{$letter-spacing-subcatch}
  --letter-spacing-catchcopy: #{$letter-spacing-catchcopy}
  --letter-spacing-title: #{$letter-spacing-title}
  // layer order
  --layer-order__fixed-content-default: #{layer-order(fixed-content-default)}
  --layer-order__fixed-content-alert: #{layer-order(fixed-content-alert)}
  --layer-order__drawer-navi: #{layer-order(drawer-navi)}
  --layer-order__dialog: #{layer-order(dialog)}
  --layer-order__loading-screen: #{layer-order(loading-screen)}
  // directory path
  --directory-path__document-root: #{directory-path(document-root)}
  --directory-path__images-directory: #{directory-path(images-directory)}
  // color scheme
  --color-white: #{$color-white}
  --color-black: #{$color-black}
  --color-beige: #{$color-beige}
  --color-gray: #{$color-gray}
  --color-teal: #{$color-teal}
  // UI color
  --ui-color__error: #{ui-color(error)}
  --ui-color__warning: #{ui-color(warning)}
  --ui-color__notice: #{ui-color(notice)}
  --ui-color__success: #{ui-color(success)}
  --ui-color__negative: #{ui-color(negative)}
  // SNS brand color
  --sns-color__facebook: #{sns-color(facebook)}
  --sns-color__twitter: #{sns-color(twitter)}
  --sns-color__tumblr: #{sns-color(tumblr)}
  --sns-color__googleplus: #{sns-color(googleplus)}
  --sns-color__line: #{sns-color(line)}
  --sns-color__hatena: #{sns-color(hatena)}
  --sns-color__youtube: #{sns-color(youtube)}
  --sns-color__pinterest: #{sns-color(pinterest)}
  --sns-color__pocket: #{sns-color(pocket)}
  --sns-color__linkedin: #{sns-color(linkedin)}
  --sns-color__feedly: #{sns-color(feedly)}
  // easing function
  --easing-function__linear: #{map-get($easing-function-list, linear)}
  --easing-function__ease: #{map-get($easing-function-list, ease)}
  --easing-function__ease-in: #{map-get($easing-function-list, ease-in)}
  --easing-function__ease-out: #{map-get($easing-function-list, ease-out)}
  --easing-function__ease-inout: #{map-get($easing-function-list, ease-inout)}
  --easing-function__ease-in-sine: #{map-get($easing-function-list, ease-in-sine)}
  --easing-function__ease-out-sine: #{map-get($easing-function-list, ease-out-sine)}
  --easing-function__ease-inout-sine: #{map-get($easing-function-list, ease-inout-sine)}
  --easing-function__ease-in-quad: #{map-get($easing-function-list, ease-in-quad)}
  --easing-function__ease-out-quad: #{map-get($easing-function-list, ease-out-quad)}
  --easing-function__ease-inout-quad: #{map-get($easing-function-list, ease-inout-quad)}
  --easing-function__ease-in-cubic: #{map-get($easing-function-list, ease-in-cubic)}
  --easing-function__ease-out-cubic: #{map-get($easing-function-list, ease-out-cubic)}
  --easing-function__ease-inout-cubic: #{map-get($easing-function-list, ease-inout-cubic)}
  --easing-function__ease-in-quart: #{map-get($easing-function-list, ease-in-quart)}
  --easing-function__ease-out-quart: #{map-get($easing-function-list, ease-out-quart)}
  --easing-function__ease-inout-quart: #{map-get($easing-function-list, ease-inout-quart)}
  --easing-function__ease-in-quint: #{map-get($easing-function-list, ease-in-quint)}
  --easing-function__ease-out-quint: #{map-get($easing-function-list, ease-out-quint)}
  --easing-function__ease-inout-quint: #{map-get($easing-function-list, ease-inout-quint)}
  --easing-function__ease-in-expo: #{map-get($easing-function-list, ease-in-expo)}
  --easing-function__ease-out-expo: #{map-get($easing-function-list, ease-out-expo)}
  --easing-function__ease-inout-expo: #{map-get($easing-function-list, ease-inout-expo)}
  --easing-function__ease-in-circ: #{map-get($easing-function-list, ease-in-circ)}
  --easing-function__ease-out-circ: #{map-get($easing-function-list, ease-out-circ)}
  --easing-function__ease-inout-circ: #{map-get($easing-function-list, ease-inout-circ)}
  --easing-function__ease-in-back: #{map-get($easing-function-list, ease-in-back)}
  --easing-function__ease-out-back: #{map-get($easing-function-list, ease-out-back)}
  --easing-function__ease-inout-back: #{map-get($easing-function-list, ease-inout-back)}
  // dark theme mode 
  &[data-theme="dark"]
    --default-color: #{$color-white}