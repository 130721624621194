@use "../../foundation/import" as *

/* -------------------------------
 *  script elements
 * ---------------------------- */
.js-loading-screen
  &.js-loading-screen--hidden
    visibility: hidden
    opacity: 0
  .js-loading-screen-logo
    opacity: 0
    &.js-loading-screen-logo--shown
      opacity: 1

.js-header 
  visibility: visible
  opacity: 1
  transition-property: opacity visibility
  transition-duration: 1.4s
  &.js-header--hidden
    opacity: 0
    visibility: hidden

