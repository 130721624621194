@use "../../foundation/import" as *

/* -------------------------------
 *  text components
 * ---------------------------- */
.u-text-en  
  font-family: 'Roboto Flex', sans-serif
  font-weight: 300
  letter-spacing: 0.1rem
  line-height: 1.78
  +media-query(md)
    line-height: 1.6
    text-align: left
.u-text-white
  color: var(--color-white)