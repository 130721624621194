@use "../../foundation/import" as *

/* -------------------------------
 *  table components
 * ---------------------------- */
.u-table-wrap
  & > table
    width: 100%
.u-table-default
  tbody  
    tr
      th,td
        vertical-align: top
        text-align: left
        padding: 10px 0
        font-size: 1.4rem
      th
        width: 150px
        font-weight: normal