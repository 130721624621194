@use "../../foundation/import" as *

/* -------------------------------
 *  common
 * ---------------------------- */
.p-cover-visual
  height: 860px
  +media-query(md)
    height: 430px
  .__image-area
    width: 100%
    height: 100%
    .__image
      object-fit: cover
      width: 100%
      height: 100%
      transform: scale(1.01)

/* -------------------------------
 *  02_about
 * ---------------------------- */
.p-about-page  
  .__heading
    font-size: 2.8rem
    line-height: 1.6
    font-weight: 300
  .__lead
    font-size: 2.2rem
    font-weight: 300
    .__caption
      font-size: 1.2rem
      display: block
      line-height: 1
      margin: 0 0 5px 0
  .__text-en
    +media-query(md)
      font-weight: 400
  .p-section-01
    .__block-depth-1
      .__block-depth-2
        display: flex
        justify-content: space-between
        align-items: flex-start
        flex-wrap: nowrap
        +media-query(md)
          flex-wrap: wrap
        .__block-depth-3
          +media-query(md)
            width: 100%
          &.__block-depth-3--1st
            flex-grow: 0
            flex-shrink: 0
            margin: 70px 0 0 0
          &.__block-depth-3--2nd
            flex-grow: 1
            flex-shrink: 1
            margin: 70px 0 0 5.4687%
            +media-query(md)
              margin: 50px 0 0 0
            .__lead
              margin: 0 0 30px 0
            .__text
              font-size: 1.4rem
              margin: 0 0 20px 0
  .p-section-02
    padding: 120px 0 0 0
    .__block-depth-1
      .__block-depth-2
        margin: 60px 0 0 0

/* -------------------------------
 *  03_project top
 * ---------------------------- */
.p-projects-page  
  .__block-depth-1
    .__block-depth-2
      &:not(:first-child)
        margin: 140px 0 0 0
  .p-project-archive
    display: flex
    justify-content: space-between
    align-items: flex-start
    flex-wrap: wrap
    .__info
      width: 35%
      +media-query(md)
        width: 100%
      .__heading
        font-size: 2.2rem
        line-height: 1.6
        font-weight: 300
      .__description
        margin: 5px 0 0 0
        font-size: 1.2rem
    .__link
      width: 60%
      +media-query(md)
        width: 100%
        margin: 30px 0 0 0
    
/* -------------------------------
 *  04_project detail
 * ---------------------------- */
.p-project-page  
  .__block-depth-1
    .__heading
      font-size: 2.2rem
      line-height: 1.6
      font-weight: 300
    .__description
      margin: 5px 0 0 0
      font-size: 1.4rem
    .__block-depth-2
      .__block-depth-3
        &:first-child
          margin: 50px 0 0 0
        &:not(:first-child)
          margin: 140px 0 0 0
          +media-query(md)
            margin: 90px 0 0 0
        .__text
          font-size: 1.4rem
        .__back
          text-decoration: none
          font-size: 1.6rem
          &::before
            content: "<"
            padding: 0 0.5em 0 0
    .__text-en
      +media-query(md)
        font-weight: 400

/* -------------------------------
 *  05_contact
 * ---------------------------- */
.p-contact-page  
  .__block-depth-1    
    .__link
      display: inline-block
      text-decoration: none
      font-size: 2.0rem
      margin: 40px 0 0 0
      border-bottom: 1px solid var(--color-black)
    .__text
      font-size: 1.4rem
      &.__text-en
        +media-query(md)
          font-weight: 400