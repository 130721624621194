@use "../foundation/import" as *

/* -------------------------------
 *  header
 * ---------------------------- */
.l-header
  grid-area: header
  padding: 70px 5.2083vw 0 5.2083vw
  position: fixed
  top: 0
  left: 0
  z-index: var(--layer-order__dialog)
  width: 100%
  +media-query(md)
    padding: 30px 5.2083vw 0 5.2083vw
  .__block-depth-1
    .__block-depth-2
      &.__block-depth-2--1st
        margin: 0 30px 0 0
        .__logo
          width: 350px
          max-width: 100%
          +media-query(md)
            width: auto
            max-width: 70%
  &.l-header--front-page
    .__logo
      transition-property: opacity visibility
      transition-duration: 1.4s
      visibility: hidden
      opacity: 0
  &.l-header--navi-open
    .__logo
      visibility: visible
      opacity: 1