@use "../../foundation/import" as *

/* -------------------------------
 *  parts styles
 * ---------------------------- */
.c-hamburger
  font-size: 0  
  width: 60px
  height: 42px
  background-image: linear-gradient(0deg, transparent 0%, transparent 48.8095%, var(--color-white) 48.8095%, var(--color-white) 51.1904%, transparent 51.1904%, transparent 100%)
  position: relative
  +media-query(md)
    width: 40px
    height: 28px
    background-image: linear-gradient(0deg, transparent 0%, transparent 48.2%, var(--color-white) 48.2%, var(--color-white) 51.8%, transparent 51.8%, transparent 100%)
  &::before,
  &::after
    content: ""
    width: 100%
    height: 1px
    background: var(--color-white)
    left: 0
    position: absolute
    transform-origin: center center
    +media-query(md)
      height: 1px
  &::before
    top: 0
    transition: transform 0s ease 0s, top 0.3s ease 0s
  &::after
    bottom: 0
    transition: transform 0s ease 0s, bottom 0.3s ease 0s
  &.c-hamburger--close
    background-image: none
    &::before,
    &::after
      width: 70px
      +media-query(md)
        width: 48px
    &::before
      top: 20px
      transform: translate(-5px, 0) rotate(35deg) 
      transition: transform 0.3s ease 0s, top 0s ease 0s
      +media-query(md)
        top: 14px
        transform: translate(-4px, 0) rotate(35deg)
    &::after
      bottom: 20px
      transform: translate(-5px, 0) rotate(-35deg)
      transition: transform 0.3s ease 0s, bottom 0s ease 0s
      +media-query(md)
        bottom: 14px
        transform: translate(-4px, 0) rotate(-35deg)

.c-slide
  position: relative
  height: 100vh
  .__slide-item
    position: absolute
    left: 0
    top: 0
    width: 100%
    height: 100%
    z-index: 1
    opacity: 0
    transition: opacity 2.0s ease 0.1s
    &.__slide-item--current
      z-index: 3
      opacity: 1
    &.__slide-item--next
      z-index: 2
      opacity: 0
    .__slide-element
      width: 100%
      height: 100%
      .__image
        object-fit: cover
        width: 100%
        height: 100%
        transform: scale(1.01)

.c-slide-navigation
  position: absolute
  z-index: 10
  bottom: 0
  left: 0
  height: auto
  width: 100%
  padding: 0 5.2083vw 70px 5.2083vw
  +media-query(md)
    padding: 0 5.2083vw 30px 5.2083vw
  .__list
    display: flex
    justify-content: flex-end
    align-items: center
    .__item
      width: 60px
      height: 2px
      border-bottom: 1px dotted var(--color-white)
      position: relative
      +media-query(md)
        width: 40px
      &:not(:first-child)
        margin: 0 0 0 30px
        +media-query(md)
          margin: 0 0 0 15px
      .__indicator
        width: 100%
        height: 3px
        background: var(--color-white)
        display: inline-block
        position: absolute
        top: 0
        left: 0
        transform: scaleX(0)
        will-change: transform
        &.__indicator--state-complate
          transform-origin: right center
          animation: slide-navi-indicator-complete 0.3s linear 0s 1 normal forwards
        &.__indicator--state-play
          animation: slide-navi-indicator-play 4s linear 0s 1 normal forwards
          transform-origin: left center

.c-drawer-navi
  position: fixed
  top: 0
  left: 0
  width: 100%          
  height: 100vh
  z-index: var(--layer-order__drawer-navi)
  visibility: hidden
  opacity: 0
  transition-property: opacity visibility
  transition-duration: 1.4s
  .__panel
    width: 100%
    height: 100%
    position: relative
    z-index: 1
    background: var(--color-teal)
  .__navi
    position: absolute
    left: 0
    top: 0
    z-index: 2
    width: 100%
    height: 100%
    & > *,
    & > * > *,
    & > * > * > .__block-depth-1
      width: 100%
      height: 100%
    .__block-depth-1
      margin: 250px 0 60px 0
      height: calc(100vh - 310px)
      overflow: auto
      +media-query(md)
        margin: 140px 0 40px 0
    .__list
      +media-query(md)
        padding: 0 20px
      .__item
        &:not(:first-child)
          margin: 100px 0 0 0
          +media-query(md)
            margin: 60px 0 0 0 
        .__link
          text-decoration: none
          line-height: 1
        & > .__link
          font-size: 2.8rem
        .__list-child
          margin: 20px 0 0 0
          padding: 0 0 0 30px
          .__item-child
            &:not(:first-child)
              margin: 10px 0 0 0
        &.__item-mulch-column
          display: flex
          .__list-child
            margin: 0
          +media-query(md)
            flex-direction: column
            .__list-child
              margin: 20px 0 0 0
  &.c-drawer-navi--open
    visibility: visible
    opacity: 1
    .__panel,
    .__navi
      transform: translate(0, 0)
      opacity: 1
      transition: transition(opacity)

.c-breadcrumb-list
  margin: 50px 0 80px 0
  .__list
    .__item
      font-size: 1.4rem
      color: var(--color-gray)
      &:not(:first-child)
        margin: 0 0 0 0.5em
        &::before
          content: ">"
          padding: 0 0.5em 0 0
      .__link
        color: var(--color-gray)
        text-decoration: none

.c-loading-screen
  position: fixed
  width: 100%
  height: 100vh
  left: 0
  top: 0
  background: var(--color-teal)
  z-index: var(--layer-order__loading-screen)        
  display: flex
  justify-content: center
  align-items: center
  padding: 0 
  visibility: visible
  opacity: 1
  transition-property: opacity visibility
  transition-duration: 1.4s
  & > *
    &:first-child
      padding: 0 20px
  .__block-depth-1
    padding: 0 20px 
    .__image
      transition-property: opacity
      transition-duration: 1.4s
      transition-delay: 1s