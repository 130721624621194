@use "../foundation/import" as *

/* -------------------------------
 *  footer
 * ---------------------------- */
.l-footer
  grid-area: footer
  padding: 0 6.639vw 75px 6.639vw
  .__block-depth-1
    display: flex
    justify-content: space-between
    align-items: flex-end
    flex-wrap: wrap
    padding: 200px 0 0 0
    +media-query(sm)
      flex-direction: column
      align-items: flex-start
    .__block-depth-2
      &.__block-depth-2--1st
        .__block-depth-3
          display: flex
          align-items: flex-end
          flex-wrap: wrap
          .__logo
            margin: 0 50px 0 0
            width: 250px
            max-width: 100%
            +media-query(sm)
              width: 180px
          .__navi
            +media-query(sm)
              margin: 40px 0
      &.__block-depth-2--2nd
        line-height: 1
        .__copyright
          font-size: 1.0rem
          letter-spacing: 0.1rem
          +media-query(sm)
            margin: 40px 0 0 0