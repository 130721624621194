@use "../../foundation/import" as *

/* -------------------------------
 *  common
 * ---------------------------- */

/* -------------------------------
 *  01_home
 * ---------------------------- */
.p-front-page
  .p-main-visual
    position: relative
    .p-background-section
      position: relative
    .p-foreground-section
      position: absolute
      top: 0
      left: 0
      width: 100%
      height: 100%
      z-index: 10
      & > *:first-child,
      & > *:first-child > *,
      & > *:first-child > * > .__block-depth-1
        width: 100%
        height: 100%
        padding: 0 20px
  .p-section-01
    padding: 150px 0 0 0
    .__heading
      font-size: 2.8rem
      line-height: 1.6
      +media-query(md)
        line-height: 1.4
    .__text
      font-size: 1.4rem
      &.__text-ja
        +media-query(md)
          line-height: 2
      &.__text-en
        +media-query(md)
          font-weight: 400
    .__block-depth-2
      &.__block-depth-2--1st
        margin: 20px 0 0 0
      &.__block-depth-2--2nd
        margin: 140px 0 0 0
        .__banners
          display: flex
          justify-content: space-between
          flex-wrap: wrap
          .__banner
            width: 30.2083%
            +media-query(md)
              width: 100%
              &:not(:first-child)
                margin: 40px 0 0 0
            .__link
              text-decoration: none
              .__box
                width: 100%
                height: 100%
                position: relative
                background: var(--color-black)
                .__image
                  width: 100%
                  transition: transition(opacity)
                .__caption
                  position: absolute
                  left: 50%
                  top: 50%
                  transform: translate(-50%, -45%)
                  z-index: 1
                  line-height: 1
                  font-size: 2.3rem
                  font-weight: 300
              +hover-style()
                .__box
                  .__image
                    opacity: 0.5